import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/Layout";
import "../styles/pages/404.scss";

const NotFoundPage = ({data}) => {
  const { footerData, navbarData } = data;
  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <div className="section gradient-section white-text not-found-section">

        <h1 className="stroke-font bold-weight not-found-number">404</h1>
        <h2 className="title-3">Not found</h2>
        <p className="pageNotFound-description mgt-s">
          La page que vous cherchez n'existe pas.
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage;


export const notfoundPageQuery = graphql`
  query NotfoundPage {
    ...LayoutFragment
  }
`